.qualidade-dorman{
  display: flex;
  margin-top: 1rem;
  justify-content: space-between
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #contact,
  #footer {
    width: 111%;
  }

  .qualidade-dorman{
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  #portfolio {
    width: 110%;
  }
}
